import React, { useEffect,useState }from 'react'
import HashLoader from "react-spinners/HashLoader";

import {baseURL} from '../../Config';
import axios from 'axios';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import './VendorStore.css'
import FlyerSection from './FlyerSection';
import LogedNavBar from '../LogedNavBar/LogedNavBar';
import { useParams, useNavigate } from 'react-router-dom'

function VendorStore() {

const getUserDetailsURL = 'api/customer/details';
const [isLoading, setIsLoading] = useState(true);
const [isauthenticated, setAuthenticated] = useState({'authenticated': false, 'name':null});
const [vendorData, setVendorData] = useState([])
const navigate =useNavigate()

//checck user status
const checkUserStatus = async (token) => 

  {

  setIsLoading(true)
  if(!token){
    setAuthenticated({'authenticated': false, 'name':null})
    setIsLoading(false)
    return
  }
  try {
      const response = await axios.get(baseURL+getUserDetailsURL,{ headers: {"Authorization" : `Bearer ${token}`} });
      if(response.status===200)
      { 
        setAuthenticated({'authenticated': true, 'name':response.data.data.name});
        setIsLoading(false)
      }

      }
  catch(error){
    setIsLoading(false)
      if(error.response.status===401){ setAuthenticated({'authenticated': false, 'name':null}); localStorage.removeItem('access_token');  }
      else{  console.log(error);   }
      return(error) 
  }
  }



//get access token
const access_token = JSON.parse(localStorage.getItem('access_token'));


const [noDataFound,setNoDataFound]=useState(false);
const { VendorUsername} = useParams();

const [flyerData, setFlyerData] = useState([])

const getCustomerFlyerListURL='api/customer/list-flyer/'
const getCustomerFlyerList = async (start,length) => 

{
    setIsLoading(true);
    try {
        const response = await axios.get(`${baseURL}${getCustomerFlyerListURL}${VendorUsername}/?start=${start}&length=${length}`);
        setIsLoading(false);
        console.log(response, "responseeee");
        
        if(response.data.success)
        {
          if(response.data.data[0].flyers.length!==0)
          {
            setVendorData(response.data.data[0])
            setFlyerData(response.data.data)
            setNoDataFound(false)
          }
          else
          {
            setVendorData(response.data.data[0])
            setFlyerData([])
            setNoDataFound(true)
          }
        }
        else
        {
          navigate('/login')
        }
      
        }
    catch(error){
        setFlyerData([])
        setIsLoading(false);
        console.log(error);
        return(error) 
    }

    setIsLoading(false);
}




useEffect(() => {
    checkUserStatus(access_token);
    getCustomerFlyerList(0,3)

   }, [])

  return (
    <>
        <LogedNavBar isauthenticated={isauthenticated} vendorData={vendorData} />

  <div className={isLoading ? 'parentDisableFull' : ''} width="100%">

  <HashLoader color={'white'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
</div>
    
    {/* <div className='container-fluid'>
      <div className='row d-flex justify-content-center'>
        <Slider/>
      </div>
    </div> */}

    <div className='container-fluid bg-light'>
      <div className='row d-flex'>

       {/* <div className='col-md-3 d-none d-xl-block d-lg-block d-md-block'>
        <SideFilterBar closeNavMenuFunction={handleCloseNavMenu} />

        <CopyRightAndTerms/>
       </div> */}


       <div className='col-md-12'>
        <div className='row ' style={{background: '#cfe2ffb5'}}>
        
        </div>

        {/* {noDataFound? */}
            {/* //    <div className='col-9 p-3 d-flex align-items-stretch justify-content-center m-auto'>
            //    <div className="d-flex flex-column">
            //      <div className="p-2"><img src={noDataFoundimg} alt={'no Data Found'} style={{maxWidth:'100%',maxHeight:'450px'}}/></div>
            //      <div className="p-2 text-center"> <h5>No Data Found !  </h5></div>
            //    </div>
         
            //  </div> */}
        
        <>
        

        <div className=''>
          <FlyerSection  flyerData={flyerData} vendorData={vendorData} VendorUsername={VendorUsername} isauthenticated={isauthenticated} />

         </div>
        </>
        
        {/* } */}



      </div>
      </div>
    </div>

    
    </>
    
  )
}

export default VendorStore