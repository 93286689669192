import React ,{useState,useEffect}from 'react'
import {businessImagePath, logo} from '../../Config'

import { useNavigate, Link} from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { CardHeader } from '@mui/material';


function LogedNavBar({isauthenticated, vendorData}) {

const navigate = useNavigate();

const handleLogOut=()=>{

        // dispatch(unauthenticate_user()); 
        localStorage.clear();
        navigate('/login') 
}


        const [anchorElNav, setAnchorElNav] = React.useState(null);
        const [anchorElUser, setAnchorElUser] = React.useState(null);

        const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
        };
        const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
        };

        const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        };

        const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        };


  return (
        <>
                        <AppBar position="sticky" style={{ background: '#0d6efd'}} >
                        <Container maxWidth="xl" style={{ padding: 0 }}>
                                <Toolbar disableGutters>
                                <Typography onClick={()=>{navigate('/')}}
                                variant="h6"
                                noWrap
                                sx={{
                                mr: 2,
                                p:1,
                                display: { xs: 'none', md: 'flex' },
                                cursor: 'pointer', // Ensure it's visually clear that this is clickable                        
                                }}
                                >
                              <img onClick={()=>{console.log("asda"); navigate('login');}} src={logo} alt={'logo'} style={{maxWidth:'120px'}}/>
                                </Typography>
                                
                              {/* <a className='btn mx-2 d-none d-lg-flex' onClick={() => navigate('/')} >
                                <img  src={logo} alt={'logo'} style={{maxWidth:'120px'}}/></a> */}

                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                                >
                                <MenuIcon />
                                </IconButton>
                                <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                        display: { xs: 'block', md: 'none',},
                                }}
                                >
                                <MenuItem key={1} className='justify-content-center' >
                                        <a className='btn ' style={{color:'white',backgroundColor:'#06368a'}} href="https://vendor.iwillfly.in/">Join as a Vendor </a>
                                </MenuItem>
                                

                                </Menu>
                                </Box>

                                <Typography onClick={()=>{navigate('/')}}
                                variant="h5"
                                noWrap
                                component="a"
                                sx={{
                               
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                               
                               
                                }}
                                >

                                <img src={logo} alt={'logo'} style={{maxWidth:'100px'}}/>
                                </Typography>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                        
                                </Box>

                                <Box sx={{ flexGrow: 0 }}>
                                
                                {isauthenticated.authenticated?
                               <>
                                <Tooltip title="Dashboard">
                              
                              <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                                      <Avatar  alt={isauthenticated.name} src="/static/images/avatar/2.jpg" />
                              </IconButton>
                              </Tooltip>
                              <Menu
                              sx={{ mt: '45px' }}
                              id="menu-appbar"
                              anchorEl={anchorElUser}
                              anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                              }}
                              keepMounted
                              transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                              }}
                              open={Boolean(anchorElUser)}
                              onClose={handleCloseUserMenu}
                              >
                              <MenuItem key={1} >
                                      <Typography textAlign="center" onClick={handleLogOut}>Logout</Typography>
                              </MenuItem>     
                              </Menu></>: <a className='btn mx-2 ' onClick={() => navigate('/login')} style={{color:'white',backgroundColor:'#06368a'}}>Login</a>
                               }
                                
                                </Box>
                                </Toolbar>

                                <CardHeader style={{background:'#fadd02', padding:"0.5rem", color:'black'}}
                                                      avatar={
                                                        <Avatar
                                                          style={{ margin: 'auto', borderRadius: '10%', }}
                                                          alt={vendorData.shop_name}
                                                          src={businessImagePath + vendorData.business_logo}
                                                          sx={{ width: 55, height: 55, bgcolor: '#fff' }}
                                                        />
                                                      }
                                                      titleTypographyProps={{variant:'subtitle1',lineHeight:1.5}}
                                                      title={vendorData.shop_name}
                                                      subheader={vendorData.location}
                                                    />
                        </Container>
                        </AppBar>
        </>

  )
}

export default LogedNavBar