import { useState, useEffect } from "react";
import HashLoader from "react-spinners/HashLoader";

const NoFlyersMessage = ({ noDataFoundimg }) => {
  const [showNoFlyers, setShowNoFlyers] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNoFlyers(true);
    }, 1000); // Delay of 0.5 second

    return () => clearTimeout(timer); // Cleanup timeout
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
      {!showNoFlyers ? (
        <div className='parentDisableFull' width="100%">

        <HashLoader color={'white'} loading={true} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
        </div>
      ) : (
        <div className="col-9 p-3 d-flex align-items-stretch justify-content-center m-auto">
          <div className="d-flex flex-column">
            <div className="p-2">
              <img
                src={noDataFoundimg}
                alt="No Data Found"
                style={{ maxWidth: "100%", maxHeight: "450px" }}
              />
            </div>
            <div className="p-2 text-center">
              <h5>No Flyers Found!</h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoFlyersMessage;
