import React ,{useEffect,useState}from 'react'

import "swiper/css";
import "swiper/css/pagination";

import { baseURL,flyerImagePath, noDataFoundimg} from '../../../src/Config.js';
import  {CaretUpFill,RocketTakeoff,HourglassSplit} from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollToTop from "react-scroll-to-top";
import { useNavigate,useLocation } from 'react-router-dom';
import './VendorStore.css'
import HashLoader from "react-spinners/HashLoader";
import NoFlyersMessage from './NoFlyersMessage.js';

function FlyerSection({flyerData, VendorUsername, isauthenticated, vendorData}) {

    const [newFlyerData, setNewFlyerData] = useState([])
    const [show, setShow] = useState(false);
    const [nextPage, setNextPage] = useState(1)
    const [loadMore,setLoadMore]=useState(true);
    const [isLoading, setIsLoading] = useState(true);

const getPlanDuration =(end_date)=> {
      var dt1 = new Date();
      var dt2 = new Date(end_date);
      
      return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
      }

const navigate =useNavigate()
const location =useLocation()

const handleForwardLogin = () => {
  navigate(`/login?next=${encodeURIComponent(location.pathname)}`);

};


const showDetailsOfFLyer = (flyerID, flyerVendorID, flyerTitle, flyerStartDate, flyerVendorShopName) => {
  const encodedFlyerID = encodeURIComponent(flyerID);
  const encodedFlyerVendorID = encodeURIComponent(flyerVendorID);
  const encodedFlyerTitle = encodeURIComponent(flyerTitle);
  const encodedFlyerStartDate = encodeURIComponent(flyerStartDate);
  const encodedFlyerVendorShopName = encodeURIComponent(flyerVendorShopName);

  navigate(`/flyers/${encodedFlyerVendorShopName}/${encodedFlyerVendorID}/${encodedFlyerTitle}/${encodedFlyerID}/${encodedFlyerStartDate}`);
};


//get access token
const access_token = JSON.parse(localStorage.getItem('access_token'));
const getCustomerFlyerListURL='api/customer/list-flyer/'
const getCustomerFlyerList = async (start,length) => 

{


  if (!isauthenticated.authenticated){
    setShow(true);
    setIsLoading(false);
  return
  }
  try {
        const response = await axios.get(`${baseURL}${getCustomerFlyerListURL}${VendorUsername}/?start=${start}&length=${length}`);
        if(response.data.success)
        {
          if(response.data.data[0].flyers.length!==0)
          {
      
            setNextPage(nextPage => nextPage*3 + 1);
            response.data.data[0].flyers.map((x)=>{  setNewFlyerData( prevnewFlyerData => [...prevnewFlyerData,x]  ) })
          }
          else
          {
            setLoadMore(false)
         
          }
        }
        else
        {
           
        }
      
        }
    catch(error){
        
        console.log(error);
        return(error) 
    }
    setIsLoading(false);
}

useEffect(() => {
    console.log(flyerData, "flyer data");
    if(flyerData.length!==0)
        {
          console.log(flyerData, "flyer data");
          
            setNewFlyerData(flyerData[0].flyers)
        
        }
     
    setNextPage(3);
    setIsLoading(false);
       }, [flyerData]) 


  return (

    <>
     <ScrollToTop smooth   viewBox="0 0 24 24"  component={<CaretUpFill color='#1976d2'/>}
  />

<div className={isLoading ? 'parentDisableFull' : ''} width="100%">

<HashLoader color={'white'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
</div>

<Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="bottom-modal"
      >
        <Modal.Header >
          <Modal.Title>Login To Continue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You can explore other offers at <b>{vendorData.shop_name}</b> & nearby by clicking login
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleForwardLogin}>Login Now</Button>
        </Modal.Footer>
      </Modal>

    {(newFlyerData.length!==0)?
    <InfiniteScroll
        //  dataLength={flyerData[0].totalRecordsWithFilter}
         dataLength={newFlyerData.length}
         next={()=>{ getCustomerFlyerList(nextPage,3)}}
         hasMore={loadMore}
         loader={<p style={{ textAlign: 'center' ,color:'#1976d2' }}>
          <HourglassSplit/>   
       </p>}
         endMessage={
            <p style={{ textAlign: 'center' ,color:'#1976d2' }}>
              <b> <RocketTakeoff/>   Yay! You have seen it all</b>
            </p>
          }>
            <div className='row'>
            {newFlyerData
            .map((flyer, index) => {
              // Find the first flyer with status 'active'
              const activeFlyer = flyer.status === 'active'?flyer:null
              // const activeFlyer = flyer.find(flyer => flyer.status === 'active');
             
              // If there's no active flyer, return null (or handle it as needed)
              if (!activeFlyer) return null;

              return (
                <div
                  className='col-xl-4 col-lg-4 col-md-6 col-12 p-3 d-flex align-items-stretch justify-content-center'
                  key={index}
                  style={{ cursor: 'pointer' }}
                >
                  <Card
                    sx={{ maxWidth: 331, width: 331 }}
                    onClick={() => {
                      showDetailsOfFLyer(
                        activeFlyer.id,
                        activeFlyer.vendor_id,
                        activeFlyer.title,
                        activeFlyer.start_date,
                        vendorData.shop_name
                      );
                    }}
                  >
                    {/* <CardHeader
                      avatar={
                        <Avatar
                          style={{ margin: 'auto', borderRadius: '10%' }}
                          alt={vendorData.shop_name}
                          src={businessImagePath + vendorData.business_logo}
                          sx={{ width: 48, height: 48, bgcolor: '#fff' }}
                        />
                      }
                      title={vendorData.shop_name}
                      subheader={vendorData.location}
                    /> */}
                    <CardMedia
                      component="img"
                      height="468"
                      image={`${flyerImagePath}${activeFlyer.thumb_image}`}
                      alt={activeFlyer.title}
                    />
                    <CardContent>
                      <h5 className='text-break'>{activeFlyer.title} </h5>
                      <Typography variant="body2" color="text.secondary ">
                        <span className="d-inline-block text-truncate" style={{ maxWidth: '100%' }}>
                          {activeFlyer.description}
                        </span>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <span style={{ color: "brown" }}>
                          {getPlanDuration(activeFlyer.end_date)} Days Left
                        </span>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}

            </div>
           

    </InfiniteScroll>:<NoFlyersMessage noDataFoundimg={noDataFoundimg} />
  }
    </>
  )
}

export default FlyerSection