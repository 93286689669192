import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes , Route, useNavigate, useLocation } from 'react-router-dom';
import Home from './Components/Home/Home';
import LoginAndSignupContainer from './Components/LoginAndSignupContainer/LoginAndSignupContainer';
import {useSelector,useDispatch} from "react-redux"
import HomeNavBar from './Components/Home/HomeNavBar/HomeNavBar';
import { useEffect, useState } from 'react';
import {authenticate_user,unauthenticate_user} from "./Redux/userAuthentication/userAuthenticationSlice"
import {set_isLoading} from "./Redux/isLoading/isLoadingSlice"
import {baseURL} from './Config';
import axios from 'axios';
import FlyerDetails from './Components/FlyerDetails/FlyerDetails';

function App() {

  //get access token
  const access_token = JSON.parse(localStorage.getItem('access_token'));

  const authenticated = useSelector(state => state.userAuthentication.authenticated)
  const getUserDetailsURL = 'api/customer/details'

  const location = useLocation();
  const navigate=useNavigate()
  const dispatch =useDispatch()

  const searchParams = new URLSearchParams(location.search);
  const nextUrl = searchParams.get("next") || location.pathname; // Default to home if no next param


  //checck user status
  const checkUserStatus = async (token) => 

  {
    dispatch(set_isLoading({isLoading:true}))
  try {
      const response = await axios.get(baseURL+getUserDetailsURL,{ headers: {"Authorization" : `Bearer ${token}`} });
      if(response.status===200)
      { 

        dispatch( authenticate_user({name:response.data.data.name})  ) ;
        dispatch(set_isLoading({isLoading:false}))
      }

      }
  catch(error){
      dispatch(set_isLoading({isLoading:false}))
      if(error.response.status===401){ dispatch(unauthenticate_user()); localStorage.removeItem('access_token');  }
      else{  console.log(error);   }
      return(error) 
  }
  }



  useEffect(() => {
   checkUserStatus(access_token);
   if (authenticated===false) {
    navigate(`/login?next=${encodeURIComponent(nextUrl)}`);
    // navigate(`/login?next=${encodeURIComponent(location.pathname)}`);
   }

  }, [authenticated,access_token])

  return (

    <>
      {authenticated? <HomeNavBar/>:null}   
      <Routes>
      <Route path='/' element={ <Home/>  } />
      <Route path='/login' element={<LoginAndSignupContainer/>} />
      {/* <Route path='/terms-conditions' element={<TermsAndCondtions/>} /> */}
      {/* <Route path='/refund-policy' element={<RefundPolicy/>} />
      <Route path='/about-us' element={<AboutUS/>} />
      <Route path='/contact-us' element={<ContactUs/>} /> */}

      <Route path='*' element={<LoginAndSignupContainer/>} />
      <Route path='/flyers/:flyerVendorShopName/:vendorId/:flyerTitle/:flyerID/:flyerStartdate' element={<FlyerDetails/>} />
      </Routes>
    
    
    </>
  );
}

export default App;
