import React, { useState } from "react";
import "./SignUp.css";
import axios from "axios";
import { baseURL } from "../../../Config.js";
import { Person, Envelope, Eye } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { authenticate_user } from "../../../Redux/userAuthentication/userAuthenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import { set_isLoading } from "../../../Redux/isLoading/isLoadingSlice";
import HashLoader from "react-spinners/HashLoader";
function SignUp(props) {
  const isLoading = useSelector((state) => state.isLoading.isLoading);
  const regURL = "api/customer/registration";
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  //SignUp
  const onSubmit = (data) => {
    dispatch(set_isLoading({ isLoading: true }));

    axios
      .post(baseURL + regURL, {
        name: data.firstName,
        email: data.email,
        password: data.password,
        otp: data.otp,
        mobile: props.mobile,
      })
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem(
            "access_token",
            JSON.stringify(response.data.data.token)
          );
          dispatch(authenticate_user({ name: "" }));
          dispatch(set_isLoading({ isLoading: false }));
          navigate("/");
        } else {
          setDisableError(false);
          dispatch(set_isLoading({ isLoading: false }));
          setMessage(response.data.message);
        }
      });
  };

  //navigate to
  const navigate = useNavigate();

  //show error message
  const [disableError, setDisableError] = useState(true);

  //error message display
  const [message, setMessage] = useState("");

  return (
    <>
      {isLoading ? (
        <div className={isLoading ? "parentDisableLight" : ""} width="100%">
          <HashLoader
            color={"#0d6efd"}
            loading={isLoading}
            size={50}
            cssOverride={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      ) : null}

      <div className="col-xl-7 col-md-6 col-lg-6  col-12">
        <div className="mt-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* firstname */}
            <label
              className="form-label"
              style={{ fontWeight: "300", color: "#676767" }}
            >
              Name
            </label>
            <div className="form-group has-icon mb-1">
              <span className="form-control-feedback">
                <Person />{" "}
              </span>
              <input
                className="form-control rounded-0 paddingonLoginandSignUp"
                {...register("firstName", {
                  required: "Please Enter Name",
                  minLength: {
                    value: 3,
                    message: "Name must be atleast 3 characters long...",
                  },
                  maxLength: {
                    value: 30,
                    message: "Name must be atmost 30 characters long...",
                  },
                })}
                placeholder="Name"
              />

              <p className="m-0" style={{ color: "red" }}>
                {errors.firstName?.message}
              </p>
            </div>

            {/* Email */}

            <label
              className="form-label"
              style={{ fontWeight: "300", color: "#676767" }}
            >
              Email Address
            </label>
            <div className="form-group has-icon mb-1">
              <span className="form-control-feedback">
                <Envelope />{" "}
              </span>

              <input
                type="email"
                className="form-control rounded-0 paddingonLoginandSignUp"
                {...register("email", {
                  required: "Email is Required...",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email is not valid",
                  },
                })}
                placeholder="Email Address"
              />

              <p className="m-0" style={{ color: "red" }}>
                {errors.email?.message}
              </p>
            </div>

            {/* password */}

            <label
              className="form-label"
              style={{ fontWeight: "300", color: "#676767" }}
            >
              Create New Password
            </label>
            <div className="form-group has-icon mb-1">
              <span className="form-control-feedback">
                <Eye />{" "}
              </span>

              <input
                type="password"
                maxLength={20}
                autoComplete="off"
                className="form-control rounded-0 paddingonLoginandSignUp"
                {...register("password", {
                  required: "Please Enter New Password",
                  minLength: {
                    value: 8,
                    message: "Password must be atleast 8 characters long...",
                  },
                })}
                placeholder="********"
              />

              <p className="m-0" style={{ color: "red" }}>
                {errors.password?.message}
              </p>
            </div>

            <h5>Verify</h5>
            <span>
              {" "}
              <h5 style={{ fontWeight: "300", fontSize: "0.8rem" }}>
                {" "}
                We have sent 6 digit OTP on{" "}
                <b style={{ fontWeight: "500" }}> +91-{props.mobile} </b>{" "}
                <span
                  style={{ color: "red", fontWeight: "500" }}
                  onClick={(e) => {
                    window.location.reload();
                  }}
                >
                  {" "}
                  Change{" "}
                </span>{" "}
              </h5>
            </span>

            {/* otp */}
            <label
              className="form-label"
              style={{ fontWeight: "300", color: "#676767" }}
            >
              Enter OTP
            </label>
            <div className="form-group has-icon mb-1">
              <span className="form-control-feedback">OTP</span>

              <input
                onInput={(e) => {
                  const newValue = e.target.value.replace(/[^\d]/g, "");
                  e.target.value = newValue;
                }}
                className="form-control rounded-0 paddingonLoginandSignUp"
                maxLength={6}
                {...register("otp", {
                  required: "Please Enter OTP",
                  minLength: {
                    value: 6,
                    message: "Enter 6 Digit OTP",
                  },
                })}
                placeholder="_ _ _ _ _ _"
              />

              <p className="m-0" style={{ color: "red" }}>
                {errors.otp?.message}
              </p>
              {disableError ? null : (
                <span style={{ color: "red" }}> {message} </span>
              )}
            </div>

            <input
              type="submit"
              className="btn btn-primary btn-lg w-100 rounded-0 p-2 shadow "
              value="SignUp"
            />
          </form>
        </div>
        <p
          className="text-secondary text-center mt-3 mb-0 text-break "
          style={{ fontSize: "0.7rem" }}
        >
          By Continuing you agree to our{" "}
          <Link to="/terms-conditions" className="text-decoration-none">
            Terms and Conditions{" "}
          </Link>{" "}
          and{" "}
          <Link to="/refund-policy" className="text-decoration-none">
            Privacy & Refund Policy
          </Link>
        </p>
        <p
          className="text-secondary text-center  text-break m-0"
          style={{ fontSize: "0.6rem" }}
        >
          <Link to="/about-us" className="text-decoration-none">
            About Us{" "}
          </Link>
          |
          <Link to="/contact-us" className="text-decoration-none">
            {" "}
            Contact Us{" "}
          </Link>
        </p>
      </div>
    </>
  );
}

export default SignUp;
