import React from 'react'
import { ArrowLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

function BackButton({btnClass}) {
  const navigate=useNavigate()
  return (
    <div>
      <button style={{position:"absolute",left:"1rem"}} type="button" className={`btn ${btnClass}  mt-2 ms-2`}  onClick={() => navigate('/login')}><ArrowLeft/></button>
    </div>
  )
}

export default BackButton
