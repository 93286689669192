import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./Redux/store";
import TermsAndCondtions from "./Components/TermsAndCondtions/TermsAndCondtions";
import RefundPolicy from "./Components/RefundPolicy/RefundPolicy";
import AboutUS from "./Components/AboutUS/AboutUS";
import ContactUs from "./Components/ContactUs/ContactUs";
import DeleteMyAccountContainer from "./Components/DeleteMyAccountContainer/DeleteMyAccountContainer";
import VendorStore from "./Components/VendorStore/VendorStore";
const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/terms-conditions" element={<TermsAndCondtions />} />
        <Route path="/delete/my-account" element={<DeleteMyAccountContainer />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/about-us" element={<AboutUS />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path='/store/:VendorUsername' element={<VendorStore/>} />
        <Route
          path="/*"
          element={
            <Provider store={store}>
              <App />
            </Provider>
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// App.use(express.static(__dirname)); //here is important thing - no static directory, because all static :)

// App.get("/*", function(req, res) {
//   res.sendFile(path.join(__dirname, "index.html"));
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
