import React ,{useState,useEffect}from 'react'
import {getitGpay, logo} from '../../../Config'
import  {GeoAltFill} from 'react-bootstrap-icons';
import {useSelector} from "react-redux"
import { useNavigate, useLocation} from 'react-router-dom';
import './HomeNavBar.css'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SideFilterBar from '../SideFilterBar/SideFilterBar';
import {unauthenticate_user} from "../../../Redux/userAuthentication/userAuthenticationSlice"
import {useDispatch} from "react-redux"
import CopyRightAndTerms from '../CopyRightAndTerms/CopyRightAndTerms';




function HomeNavBar({setLoadMore,setDataNumber}) {


  


const dispatch =useDispatch()
const handleLogOut=()=>{

        dispatch(unauthenticate_user()); localStorage.clear();
        navigate('/login') 
}


const name = useSelector(state => state.userAuthentication.name)
const loadLocation = useSelector(state => state.loadLocation.loadLocation);
const navigate =useNavigate()
const location = useLocation();


        const [locationName, setLocationName] = useState(null)
        const [anchorElNav, setAnchorElNav] = React.useState(null);
        const [anchorElUser, setAnchorElUser] = React.useState(null);

        const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
        };
        const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
        };

        const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        };

        const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        };

useEffect(() => {

       const  location_name=JSON.parse(localStorage.getItem('location_value'));
       setLocationName(location_name?location_name.locationName:null)
            
        }, [loadLocation])

  return (
        <>
                        <AppBar position="sticky" style={{ background: '#0d6efd' }} >
                        <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Typography  onClick={() => {
                                        if (location.pathname === "/") {
                                        window.location.reload(); // Force reload when already on '/'
                                        } else {
                                        navigate("/");
                                        }
                                }}
                                variant="h6"
                                noWrap
                                sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                                           
                                }}
                                >
                              <img src={logo} alt={'logo'} style={{maxWidth:'100px'}}/>
                                </Typography>

                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                                >
                                <MenuIcon />
                                </IconButton>
                                <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                        display: { xs: 'block', md: 'none' },
                                }}
                                >
                                <MenuItem key={1} className='justify-content-center' >
                                        <a className='btn ' style={{color:'white',backgroundColor:'#06368a'}} href="https://vendor.iwillfly.in/">Join as a Vendor </a>
                                </MenuItem>

                                <MenuItem key={2} className='justify-content-center' >
                                        <GeoAltFill/> {locationName?<>{locationName}</>:<>Choose Location</>}
                                </MenuItem>


                                
                                <MenuItem key={3} >
                                        <SideFilterBar closeNavMenuFunction={handleCloseNavMenu}/>
                                </MenuItem>
                                <div key={4} className='col-8 m-auto' >
                                        
                                        <CopyRightAndTerms/>
                                        
                                       
                                </div>

                                </Menu>
                                </Box>
                                <Typography onClick={()=>{navigate('')}}
                                variant="h5"
                                noWrap
                                component="a"
                                sx={{
                               
                                display: { xs: 'flex', md: 'none' },
                                justifyContent:"center",
                                flexGrow: 1,
                               
                               
                                }}
                                >
                                <img src={logo} alt={'logo'} style={{maxWidth:'100px'}}/>
                                 
                                </Typography>
                              
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                        <Typography style={{color:'#f3da09'}}>
                                                     <GeoAltFill/> {locationName?<>{locationName}</>:<>Choose Location</>}
                                        </Typography>
                                </Box>

                                <a className='btn mx-2 d-none d-xl-block d-lg-block d-md-block' href='https://vendor.iwillfly.in/' style={{color:'white',backgroundColor:'#06368a'}}>Join as a Vendor </a>
                               
                               
                                <Box sx={{ flexGrow: 0 }}>
                                
                               
                                <Tooltip title="Dashboard">
                                <>
                                <IconButton sx={{ p: 0 }}>

                                <a href="https://play.google.com/store/apps/details?id=app.iwillfly.customer&pcampaignid=web_share" className="text-decoration-none">
        
                                       <Avatar style={{width:"100%"}}  alt={name} src={getitGpay} />
                                       
                                </a>
                                </IconButton>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                              
                                        <Avatar  alt={name} src="/static/images/avatar/2.jpg" />
                                </IconButton>
                                </>
                                </Tooltip>
                                
                                <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                >
                                <MenuItem key={1} >
                                        <Typography textAlign="center" onClick={handleLogOut}>Logout</Typography>
                                </MenuItem>     
                                </Menu>
                                </Box>
                                </Toolbar>
                        </Container>
                        </AppBar>
        </>

  )
}

export default HomeNavBar